<template>
  <f7-sheet class="post-sheet comment-sheet" :class="'pcs_' + postData?.PostKey" swipe-to-close backdrop>
    <f7-page-content>
      <div v-if="postCommentList && postCommentList.length > 0" class="comment-list">
        <div v-for="comment in postCommentList" :key="'cmt' + comment.PostCommentKey" class="comment">
          <div class="profile">
            <img :src="$h.getImage(comment.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" loading="lazy" />
            <div @click="onViewInfluencer(comment?.UserName)">
              <h3>{{ $h.getFullName(comment) }}</h3>
              <p>{{ $h.formatDateFromNow(comment.LastModified) }}</p>
            </div>

            <f7-link v-if="comment?.IsSelfComment === 1" class="actions" popover-open=".commentActions" @click="selectComment(comment)">
              <font-awesome-icon :icon="['far', 'ellipsis-h']" fixed-width />
            </f7-link>
          </div>

          <div class="content">
            {{ $h.formatCommentText(comment.Text) }}
          </div>
        </div>

        <f7-link v-if="hasMoreComment" class="loadmore no-ripple" @click="getPostCommentList">{{ $t.getTranslation("LBL_LOAD_MORE_COMMENTS") }}</f7-link>
      </div>

      <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_COMMENTS_EMPTY')" />

      <div class="create-comment">
        <f7-list id="formComment" form no-hairlines>
          <f7-list-input
            v-model:value="formData.Comment"
            name="Comment"
            :label="$t.getTranslation('LBL_COMMENT')"
            :required="validationRules?.Comment?.required"
            :minlength="validationRules?.Comment?.minimumLength"
            :maxlength="validationRules?.Comment?.maximumLength"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            floating-label
            outline
            type="textarea"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-item>
            <f7-button fill large preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableCommentButton" @click="saveComment">
              {{ $t.getTranslation("LBL_COMMENT") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </f7-page-content>
  </f7-sheet>

  <f7-popover class="commentActions">
    <f7-list>
      <f7-list-item link="#" popover-close :title="$t.getTranslation('LBL_EDIT')" @click="editComment"></f7-list-item>
      <f7-list-item link="#" popover-close :title="$t.getTranslation('LBL_DELETE')" @click="deleteComment"></f7-list-item>
    </f7-list>
  </f7-popover>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, computed, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";
import { useStore } from "@/store";

// import NoDataFoundComponent from '@/components/NoDataFoundComponent.vue'

export default defineComponent({
  name: "PostCommentComponent",
  components: {
    NoDataFoundComponent: defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue")),
  },
  props: { postData: Object, lazy: { type: Boolean, default: false } },
  emits: ["updateTotalComment"],
  setup(props, { emit }) {
    const store = useStore();

    const $t = inject("$translation");
    const $f7router = f7.views.get("#main-view").router;

    const postCommentList = ref([]);
    const postCommentPage = ref(1);
    const postCommentLastPage = ref(0);
    const selectedComment = ref(false);

    const isButtonProcessing = ref(false);
    const formData = reactive({
      PostCommentKey: "",
      Comment: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const getPostCommentList = async () => {
      let ret = await get("/post/comment/list", { PostKey: props.postData.PostKey, page: postCommentPage.value, size: 10 });

      if (ret?.data && ret?.data.length > 0) {
        for (let item of ret.data) {
          postCommentList.value.push(item);
        }
      }

      postCommentPage.value++;
      postCommentLastPage.value = ret.lastPage;
    };

    onMounted(() => {
      if (props.postData.PostKey && !props.lazy) getPostCommentList();
    });

    const hasMoreComment = computed(() => {
      return postCommentLastPage.value >= postCommentPage.value;
    });

    const showCommentSheet = async (postKey, reload) => {
      if (reload) {
        await getPostCommentList();
      }
      f7.sheet.get(`.comment-sheet${!helpers.isBlank(postKey) ? ".pcs_" + postKey : ""}`).open();
    };

    const selectComment = (comment) => {
      selectedComment.value = comment;
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableCommentButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const saveComment = async () => {
      if (!helpers.isUserLogin()) {
        $f7router.navigate({ name: "loginPage" });
        f7.sheet.get(".comment-sheet").close();
        return;
      }

      let isValid = validate(true);

      if (isValid) {
        isButtonProcessing.value = true;

        await post("/post/comment/save", {
          PostKey: props.postData.PostKey,
          Text: formData?.Comment,
          PostCommentKey: formData?.PostCommentKey != "" ? formData?.PostCommentKey : "",
        });

        emit("updateTotalComment", +1);
        isButtonProcessing.value = false;

        postCommentList.value = [];
        postCommentPage.value = 1;
        await getPostCommentList();

        helpers.clearFormData(formData);
        helpers.resetForm("form#formComment");
      }
    };

    const editComment = () => {
      if (selectedComment?.value && selectedComment.value?.PostCommentKey != "") {
        formData.PostCommentKey = selectedComment.value.PostCommentKey;
        formData.Comment = selectedComment.value.Text;
      }
    };

    const deleteComment = () => {
      if (selectedComment?.value && selectedComment.value?.PostCommentKey != "") {
        helpers.createConfirmation({
          message: $t.getTranslation("LBL_CONFIRM_DELETE_POST_COMMENT"),
          confirm: async () => {
            await post("/post/comment/delete", {
              PostKey: props.postData.PostKey,
              PostCommentKey: selectedComment.value.PostCommentKey,
            });

            emit("updateTotalComment", -1);

            postCommentList.value = [];
            postCommentPage.value = 1;
            await getPostCommentList();
          },
        });
      }
    };

    return {
      postCommentList,
      showCommentSheet,
      hasMoreComment,
      getPostCommentList,
      formData,
      validationRules,
      isButtonProcessing,
      isDisableCommentButton,
      saveComment,
      selectComment,
      editComment,
      deleteComment,
    };
  },
});
</script>
